.TagLinks {
  @apply -mx-2 flex;
}

.TagLinks_button {
  @apply mx-2
    min-w-max
    rounded-sm
    border
    border-shade-300
    bg-shade-100
    p-2.5
    text-xs
    leading-none
    no-underline
    transition-colors
    duration-300;
}

.TagLinks_button:not(.TagLinks_button___disabled):hover {
  @apply border-transparent bg-accent-blue text-primary-black;
}

.TagLinks_button.TagLinks_button___active {
  @apply bg-accent-blue text-primary-black !important;
}

@screen lg {
  .TagLinks_button {
    @apply text-sm;
  }
}
