.AppLogo {
  @apply flex
    cursor-pointer
    select-none
    items-center
    divide-x-2
    divide-solid;
}

.AppLogo_imageContainer {
  @apply mr-4 w-36;
}

.AppLogo_titleContainer {
  @apply px-4;
}

.AppLogo_title {
  @apply -mb-px text-xl;
}

.AppLogo_subtitle {
  @apply mr-2 text-sm text-primary-white;
}

.AppLogo_releaseLabel {
  @apply inline-block
    rounded-[3px]
    bg-accent-blue
    px-1
    text-xs
    text-primary-black;
}

@screen lg {
  .AppLogo_title {
    @apply text-2xl;
  }

  .AppLogo_subtitle {
    @apply text-base;
  }

  .AppLogo_releaseLabel {
    @apply text-sm;
  }
}
