.PlaybookContentHeader {
  @apply flex
    w-full
    items-center
    justify-between
    gap-4
    self-stretch
    bg-black
    px-4
    pb-8
    pt-12
    text-xl
    font-bold
    transition-all
    duration-300;
}

.PlaybookContentHeader___sticky {
  @apply sticky top-0 z-10 py-6 text-xs;
}

.PlaybookContentHeader_item {
  @apply flex
    w-max
    items-center
    justify-center
    rounded-md
    px-4
    py-3;
}

.PlaybookContentHeader___sticky .PlaybookContentHeader_item {
  @apply w-full;
}

.PlaybookContentHeader_itemIcon {
  @apply mx-2
    h-4
    w-4
    rounded-sm;
}

.PlaybookContentHeader_item___client {
  background: var(--primary-gradients-blue-tint);
}

.PlaybookContentHeader_item___ea {
  background: var(--primary-gradients-cool-black);
}

.PlaybookContentHeader_item___client .PlaybookContentHeader_itemIcon {
  @apply bg-accent-blue;
}

.PlaybookContentHeader_item___ea .PlaybookContentHeader_itemIcon {
  @apply bg-shade-200;
}

@screen lg {
  .PlaybookContentHeader {
    @apply justify-center pb-4 pt-8;
  }

  .PlaybookContentHeader___sticky {
    @apply py-4 text-base;
  }

  .PlaybookContentHeader_item {
    @apply mx-auto max-w-fit;
  }
}
