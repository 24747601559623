.Library {
  @apply flex
    h-full
    flex-grow
    flex-col
    overflow-x-hidden
    bg-black;
}

.Library_navigation {
  @apply mb-8
    flex
    flex-col space-y-8
    px-6
    py-8;
}

.Library_navigationContainer {
  @apply relative
    flex
    grow
    items-center
    justify-end
    space-x-5;
}

.Library_navigationSupportButton {
  @apply h-5 w-5 rounded-full border border-primary-white bg-primary-black text-sm hover:border-accent-blue;
}

.Library_navigationSupport {
  @apply flex grow-0 content-center;
}

.Library_search {
  @apply flex-grow;
}

.Library_searchInput {
  @apply flex
    w-full
    gap-3
    rounded-sm
    bg-shade-200
    px-6
    py-4
    placeholder:text-shade-300;
}

.Library_searchInputControl {
  @apply w-full
    bg-transparent
    text-sm
    outline-none;
}

.Library_searchIcon {
  @apply flex
    cursor-pointer
    items-center
    justify-center;
}

.Library_searchIcon > figure {
  @apply text-xl;
  width: 1em;
  height: 1em;
}

.Library_sectionIcon {
  @apply text-2xl;
}

.Library_content {
  @apply flex flex-grow flex-col;
}

.Library .Footer a {
  @apply no-underline;
}

@screen lg {
  .Library_navigation {
    @apply flex-row
      space-x-12 space-y-0
      py-16
      pl-14
      pr-8;
  }

  .Library_navigationContainer {
    @apply space-x-12;
  }

  .Library_searchInput {
    @apply px-3 py-6;
  }

  .Library_pillarFilters {
    @apply flex
      flex-row
      space-x-4
      overflow-x-auto;
  }

  .Library_filterButton {
    @apply min-w-[12rem]
      p-2;
  }

  .Library_filterButtonText {
    @apply text-center text-sm;
  }

  .Library_filterButtonTags {
    @apply text-center;
  }

  .Library_filterButtonToggle {
    @apply hidden;
  }

  .Library_navigationSupportButton {
    @apply mr-4 h-6 w-6 text-base;
  }

  .Library_content {
    @apply mb-16;
  }
}
