.PlaybookContent {
  @apply flex
    flex-col
    items-center
    justify-center
    bg-black
    px-2
    pb-12;
}

.PlaybookContent_wrapper {
  @apply relative w-full;
}

.PlaybookContent_dashedLineExtenderWrapper {
  @apply hidden;
}

.PlaybookContent_buttons {
  @apply flex
    w-full
    flex-col
    space-y-6
    px-4
    pb-8;
}

.PlaybookContent_button .Button__iconWrapper {
  @apply text-xl;
}

.PlaybookContent_nextPlaybook {
  @apply flex
    flex-col
    gap-4
    self-stretch
    p-4;
}

.PlaybookContent_nextPlaybookLink {
  @apply no-underline;
}

.PlaybookContent_nextPlaybookTitle,
.PlaybookContent_nextPlaybookHeadingText {
  @apply self-stretch text-2xl text-primary-white;
}

.PlaybookContent_nextPlaybookCard {
  @apply flex
    items-center
    gap-4
    self-stretch
    rounded
    bg-shade-100
    p-5;
}

.PlaybookContent_nextPlaybookCardDetails {
  @apply flex
    flex-col
    gap-2
    self-stretch;
}

.PlaybookContent_nextPlaybookCard:hover {
  background: linear-gradient(
      0deg,
      rgba(244, 240, 243, 0.04) 0%,
      rgba(244, 240, 243, 0.04) 100%
    ),
    #1a1a2c;
}

.PlaybookContent_nextPlaybookOverview {
  @apply text-sm text-shade-400;
}

.PlaybookContent_openPlaybookButton {
  @apply opacity-0 transition duration-300 ease-in-out;
}

.PlaybookContent_nextPlaybookCard:hover .PlaybookContent_openPlaybookButton {
  @apply opacity-100;
}

.PlaybookContent_openPlaybookButtonContent {
  @apply flex;
}

.PlaybookContent_openPlaybookButtonContent {
  @apply flex items-center gap-2 text-accent-blue;
}

.PlaybookContent_openPlaybookButtonIcon {
  @apply text-2xl;
}

@screen lg {
  .PlaybookContent {
    @apply px-9 pb-0;
  }

  .PlaybookContent_dashedLineExtenderWrapper {
    @apply grid h-16 grid-cols-2;
  }

  .PlaybookContent_buttons {
    @apply pb-0;
  }

  .PlaybookContent_button {
    @apply mx-auto max-w-xs;
  }

  .PlaybookContent_button___share {
    @apply hidden;
  }
}
