.LibraryHomeCard {
  @apply flex
    min-w-[220px]
    max-w-[220px]
    cursor-pointer
    flex-col
    space-y-4
    rounded
    border
    border-shade-200
    p-4
    no-underline
    transition-all
    duration-300;
}

.LibraryHomeCard_title {
  @apply flex-grow font-bold;
}

@screen lg {
  .LibraryHomeCard {
    @apply min-w-[340px] max-w-[340px];
  }

  .LibraryHomeCard_title {
    @apply text-lg;
  }
}
