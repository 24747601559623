/* TODO: Consolidate similar classed styles */

.Timeline_container {
  @apply grid
    h-full
    w-full
    grid-cols-2;
}

.Timeline_container.Timeline_container___isEATaskNext {
  @apply lg:-mb-24;
}

.Timeline_container.Timeline_container___isPersonalTaskNext {
  @apply lg:-mb-28;
}

.Timeline_mobile_dashed_border_container {
  @apply flex
    h-12
    w-full
    justify-center
    lg:hidden;
}

.Timeline_mobile_dashed_border {
  @apply border-l-2
    border-dotted
    border-neutral-700;
}

.Timeline_ea_task {
  @apply col-span-2
    flex
    items-center
    lg:col-span-1;
}

.Timeline_dashed_border {
  @apply col-span-1
    h-full
    border-r-2
    border-dashed
    border-neutral-700;
}

.Timeline_ea_task_connector_alignment {
  @apply hidden
    lg:relative
    lg:right-[0.70em]
    lg:flex
    lg:items-center;
}

.Timeline_ea_task_connector {
  @apply h-0.5
  w-4
    bg-gradient-to-r
    from-neutral-400
    to-white/10;
}

.Timeline_ea_task_checkpoint {
  @apply h-5
    w-5
    rounded
    bg-shade-200;
}

.Timeline_personal_task_connector_alignment {
  @apply hidden
    lg:relative
    lg:left-[0.70em]
    lg:flex
    lg:items-center;
}

.Timeline_personal_task_connector {
  @apply h-0.5
    w-4
    bg-gradient-to-r
    from-white/10 
    to-neutral-400;
}

.Timeline_personal_task_checkpoint {
  @apply h-5
    w-5
    rounded
    bg-shade-200;
}

.Timeline_personal_task {
  @apply col-span-2
    flex
    h-full
    items-center
    lg:col-span-1
    lg:border-r-2
    lg:border-dashed
    lg:border-neutral-700;
}

.PersonalTask_container {
  @apply relative
    mx-4
    w-full
    lg:left-2
    lg:mx-0;
}

.PersonalTask {
  @apply ml-auto
    h-full
    rounded-md
    p-4
    lg:my-4
    lg:max-w-md;
  background: var(--primary-gradients-blue-tint);
}

.PersonalTask_header {
  @apply mb-5
    flex
    items-center
    justify-between
    font-bold;
}

.PersonalTask_title {
  @apply text-xl
    text-accent-blue
    lg:text-2xl;
}

.PersonalTask_header .PersonalTask_title {
  @apply flex-grow;
}

.PersonalTask_duration {
  @apply flex items-center space-x-2;
}

.PersonalTask_durationIcon {
  @apply text-2xl;
}

.PersonalTask_durationText {
  @apply text-white;
}

.PersonalTask_description {
  @apply mb-4 text-base;
}

.PersonalTask_sub_description {
  @apply rounded-md
  bg-shade-400
    bg-opacity-10
    p-4
    text-sm;
}

.EaTask_container {
  @apply relative
    mx-4
    w-full
    lg:right-2
    lg:mx-0;
}

.EaTask {
  @apply h-full
    rounded-md
    p-4
    lg:my-4
    lg:max-w-md;
  background: var(--primary-gradients-cool-black);
}

.EaTask_header {
  @apply mb-5
    flex
    items-center
    justify-between
    font-bold;
}

.EaTask_title {
  @apply text-xl
    lg:text-2xl;
}

.EaTask_description {
  @apply mb-4 text-base;
}

.EaTask_sub_description {
  @apply rounded-md
    bg-shade-200
    p-4
    text-sm;
}
