.LibraryHomePreviewCard {
  @apply flex
    min-w-[220px]
    max-w-[220px]
    scale-110
    transform
    cursor-pointer
    flex-col
    space-y-4
    rounded-sm
    border
    border-accent-blue
    p-4
    text-white
    no-underline
    transition-all
    duration-300;
  background: var(--primary-gradients-blue-tint);
}

.LibraryHomePreviewCard_title {
  @apply flex-grow text-sm transition-all duration-300;
}

.LibraryHomePreviewCard_authors {
  @apply text-sm;
}

@screen lg {
  .LibraryHomePreviewCard {
    @apply min-w-[340px] max-w-[340px];
  }

  .LibraryHomePreviewCard_title {
    @apply border-b
    border-primary-white
    pb-4
    text-lg;
  }

  .LibraryHomePreviewCard_authors {
    @apply text-base;
  }
}
