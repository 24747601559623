.SharePlaybook {
  @apply flex flex-col gap-8;
}

.SharePlaybook_ButtonLinks {
  @apply flex flex-col gap-4;
}

.SharePlaybook_ButtonLinks > button {
  @apply w-full;
}

.SharePlaybook_URLContainer {
  @apply flex flex-col gap-4;
}

.SharePlaybook_inputControl {
  @apply w-full
    rounded-lg
    bg-shade-200
    px-3
    py-4
    text-shade-300
    outline-none;
}

.SharePlaybook .Button__iconWrapper {
  @apply text-xl;
}

@screen lg {
  .SharePlaybook_ButtonLinks {
    @apply flex-row;
  }

  .SharePlaybook_URLContainer {
    @apply flex-row;
  }

  .SharePlaybook_URLContainer .Button {
    @apply w-1/3;
  }
}
