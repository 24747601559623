.Playbook {
  @apply grid grid-cols-12 h-screen
}

.Playbook_summary {
  @apply col-span-12
}

.Playbook_content {
  @apply col-span-12
}

@screen lg {
  .Playbook_summary {
    @apply col-span-4
  }

  .Playbook_content {
    @apply col-span-8
  }
}