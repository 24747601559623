.SupportModal_body {
  @apply flex flex-col gap-4 text-sm;
}

.SupportModal_actions {
  @apply mt-4 flex flex-col gap-7 lg:gap-10;
}

.SupportModal_actionBlock {
  @apply flex w-full flex-col justify-start gap-4;
}

/* TODO: Remove font-body to when headings styles are updated */
.SupportModal_actionBlockHeading {
  @apply text-xl text-accent-blue lg:text-2xl;
}

.SupportModal_actionBlockSubHeading {
  @apply flex-grow;
}

.SupportModal_actionBlockButton button {
  @apply w-full;
}

@screen lg {
  .SupportModal_actions {
    @apply flex-row;
  }
}
