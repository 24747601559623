.LoadingSpinner {
  @apply flex 

}

.LoadingSpinner_animationWrapper {
  @apply relative h-auto w-max mb-4;
  -webkit-mask-image: url('assets/hourglass-mask.svg');
  mask-image: url('assets/hourglass-mask.svg');
  -webkit-animation: hourglass-mobile 1.5s linear infinite;
  animation: hourglass-mobile 1.5s linear infinite;
}

.LoadingSpinner_animationIcon {
  @apply w-8 lg:w-12;
}

@screen lg {
  .LoadingSpinner_animationWrapper {
    -webkit-animation: hourglass-desktop 1.5s linear infinite;
    animation: hourglass-desktop 1.5s linear infinite;
  }
}

@-webkit-keyframes hourglass-mobile {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -0em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -2em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -1em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -1em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #fff 0 -1em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -1em 0 0;
  }
}

@keyframes hourglass-mobile {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -0em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -2em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -1em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -1em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #fff 0 -1em 0 0, inset #000 0 -1em 0 0,
      inset #fff 0 -1em 0 0;
  }
}

@-webkit-keyframes hourglass-desktop {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -0em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -2em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #fff 0 -2em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -2em 0 0;
  }
}

@keyframes hourglass-desktop {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -0em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #fff 0 -2em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #fff 0 -2em 0 0, inset #000 0 -2em 0 0,
      inset #fff 0 -2em 0 0;
  }
}