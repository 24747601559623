.Modal {
  @apply fixed
    top-0
    z-50
    flex
    h-full
    w-full
    bg-black
    bg-opacity-80
    px-6
    py-12
    lg:p-12;
}

.Modal_content {
  @apply relative
    m-auto
    flex
    h-auto
    w-full
    flex-col
    justify-center
    gap-4
    rounded
    border
    border-shade-200
    bg-shade-100
    p-6
    lg:p-12;
  max-width: 821px;
}

.Modal_close {
  @apply absolute right-4 top-4 text-2xl;
}

.Modal_close figure {
  @apply cursor-pointer opacity-60 transition-opacity duration-300 hover:opacity-100;
}

.Modal_headingText {
  @apply text-xl text-accent-blue lg:text-2xl;
}

@screen lg {
  .Modal_body {
    @apply flex-row space-x-3 space-y-0;
  }
}
