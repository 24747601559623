.PillarNavigation_controls {
  @apply px-6 lg:px-14;
}

.PillarNavigation_filterButtonToggle {
  @apply flex
    w-full
    items-center
    justify-between
    space-x-2
    rounded-md
    border
    border-transparent
    py-7
    pl-6
    pr-4
    transition-colors
    duration-300
    hover:border-accent-blue;
}

.PillarNavigation_filterButtonText {
  @apply w-full
    text-left
    text-sm
    uppercase
    tracking-[0.15em];
}

.PillarNavigation_pillarFilters {
  @apply mt-6
    pb-px;
}

.PillarNavigation_filterButton {
  @apply flex
    w-full
    flex-col
    justify-center
    rounded-md
    border
    border-transparent
    p-4
    text-primary-white
    no-underline
    transition-colors
    duration-300;
}

.PillarNavigation_filterButton:hover {
  @apply border-accent-blue;
}

.PillarNavigation_filterButton___active {
  @apply cursor-default border-accent-blue !important;
}

.PillarNavigation_filterButton___all {
  @apply py-7;
}

.PillarNavigation_filterButtonTags {
  @apply block w-full text-left text-xs normal-case tracking-normal text-shade-300;
}

@screen lg {
  .PillarNavigation_pillarFilters {
    @apply flex
      flex-row
      space-x-4
      overflow-x-auto;
  }

  .PillarNavigation_filterButton {
    @apply min-w-[12rem]
      p-2;
  }

  .PillarNavigation_filterButtonToggle {
    @apply hidden;
  }

  .PillarNavigation_filterButtonText {
    @apply text-center text-sm;
  }

  .PillarNavigation_filterButtonTags {
    @apply text-center;
  }
}
