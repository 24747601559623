:root {
  --primary-gradients-cool-black: linear-gradient(
    135deg,
    #1e1e33 0%,
    #030311 100%
  );
  --primary-gradients-blue-tint: linear-gradient(
    315deg,
    #12121f 0%,
    #202a29 100%
  );
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-primary-black;
  }

  a {
    @apply underline underline-offset-4;
  }
}

@layer components {
  .___bgGradient {
    background: var(--primary-gradients-cool-black);
  }

  .___content {
    @apply mt-10 lg:mt-14;
  }

  .___section {
    @apply my-4 px-6 lg:px-14;
  }

  .___sectionHeading {
    @apply mb-6
      flex
      items-center
      space-x-2
      border-b
      border-white
      pb-2;
  }

  .___grid {
    @apply mx-auto grid w-full grid-cols-1 gap-y-8;
  }

  .___gridWrapper {
    @apply flex justify-center;
  }

  @screen md {
    .___grid {
      @apply grid-cols-2 gap-x-6;
    }
  }

  @screen lg {
    .___grid {
      @apply grid-cols-3;
    }
  }

  @screen xl {
    .___grid {
      @apply grid-cols-4;
    }
  }
}
