.PlaybookSummary {
  @apply h-full;
}

.PlaybookSummary_container {
  @apply sticky inset-y-0 px-6 py-8;
}

.PlaybookSummary_heading {
  @apply mt-12;
}

.PlaybookSummary_viewLibraryButton {
  @apply mt-6 max-w-[13.25rem];
}

.PlaybookSummary_pillar {
  @apply text-sm
    uppercase
    tracking-[0.15em]
    no-underline;
}

.PlaybookSummary_title {
  @apply mb-4
    mt-2
    text-[2rem]
    font-normal
    leading-[3.125rem];
}

.PlaybookSummary_metadata {
  @apply flex flex-col space-y-4;
}

.PlaybookSummary_tags {
  @apply -mx-2 flex;
}

.PlaybookSummary_tag {
  @apply mx-2
    min-w-max
    rounded-sm
    border
    border-zinc-500
    bg-zinc-700
    px-2
    py-1;
}

.PlaybookSummary_details {
  @apply mt-12;
}

.PlaybookSummary_detailsTitle {
  @apply mb-4 text-xl font-bold;
}

.PlaybookSummary_detailsDescription:not(:last-child) {
  @apply mb-12;
}

.PlaybookSummary_templates {
  @apply ml-4 list-disc text-accent-blue;
}

.PlaybookSummary_templateLink {
  @apply block
    overflow-hidden
    text-ellipsis
    no-underline
    hover:underline;
}

.PlaybookSummary_shareSection {
  @apply mt-14 flex space-x-4;
}

.PlaybookSummary_shareSection .Button__iconWrapper {
  @apply text-xl;
}

.PlaybookSummary_shareButtonText {
  @apply text-sm;
}

.PlaybookSummary_shareIcon {
  @apply mb-2;
}

@screen lg {
  .PlaybookSummary_title {
    @apply text-[2.5rem];
  }

  .PlaybookSummary_container {
    @apply px-8 py-12;
  }

  .PlaybookSummary_shareSection {
    @apply max-w-fit justify-between;
  }
}

@screen xl {
  .PlaybookSummary_metadata {
    @apply flex-row items-center space-x-4 space-y-0;
  }
}
