.LibraryCard {
  @apply flex
    cursor-pointer
    flex-col
    space-y-4
    rounded-sm
    border
    border-shade-200
    p-5
    transition-all
    duration-300;
}

.LibraryCard_details,
.LibraryCard_links {
  @apply flex flex-col space-y-3;
}

.LibraryCard_details {
  @apply flex-grow;
}

.LibraryCard_titleContainer {
  @apply border-b
  border-primary-white
  border-opacity-20
  pb-3;
}

.LibraryCard_title {
  @apply align-middle text-sm font-bold;
}

.LibraryCard_result {
  @apply h-1/2
    text-xs
    text-shade-300
    transition-colors
    duration-300;
}

.LibraryCard_authors {
  @apply text-xs;
}

.LibraryCard:hover {
  @apply z-10
    border
    border-accent-blue
    transition-all
    duration-300;
  background: var(--primary-gradients-blue-tint);
}

.LibraryCard:hover .LibraryCard_result {
  @apply text-white;
}

@screen lg {
  .LibraryCard_title {
    @apply text-xl;
  }

  .LibraryCard_authors {
    @apply text-base;
  }
}
