.App {
  @apply flex
    flex-col
    max-w-[100vw]
    min-h-screen;
}

.App_logo {
  @apply w-48;
}
