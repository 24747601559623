.LibraryHomeSection {
  @apply mb-4 p-6;
}

.LibraryHomeSection.LibraryHomeSection___featured {
  @apply mt-8 bg-shade-200;
}

.LibraryHomeSection_heading {
  @apply mb-6
    flex
    items-center
    space-x-2
    border-b
    border-white
    pb-2;
}

.LibraryHomeSection_icon {
  @apply text-2xl;
}

.LibraryHomeSection_headingTitle {
  @apply text-xl capitalize;
}

.LibraryHomeSection_pillarLink {
  @apply relative
    left-4
    ml-2
    text-xs
    text-accent-blue
    no-underline
    hover:text-primary-white;
}

.LibraryHomeSection_content {
  @apply relative w-full;
}

.LibraryHomeSection_scrollNavigation {
  @apply hidden;
}

.LibraryHomeSection_contentWrapper {
  @apply flex
    snap-x
    snap-mandatory
    items-stretch
    space-x-4
    overflow-x-auto
    scroll-smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.LibraryHomeSection_contentWrapper::-webkit-scrollbar {
  @apply hidden;
}

.LibraryHomeSection_contentWrapper > * {
  @apply snap-start;
}

.LibraryHomeSection_previewCard {
  @apply absolute -top-12 z-10;
}

@screen lg {
  .LibraryHomeSection {
    @apply px-14 py-10;
  }

  .LibraryHomeSection.LibraryHomeSection___featured {
    @apply mt-14;
  }

  .LibraryHomeSection_heading {
    @apply mb-9;
  }

  .LibraryHomeSection_pillarLink {
    @apply hidden;
  }

  .LibraryHomeSection_heading:hover .LibraryHomeSection_pillarLink {
    @apply flex;
  }

  .LibraryHomeSection_scrollNavigation {
    @apply absolute
      bottom-0
      top-0
      block
      border
      border-shade-200
      px-2
      text-2xl;
    background: var(--primary-gradients-cool-black);
  }

  .LibraryHomeSection_scrollNavigation___left {
    @apply -left-14
      rounded-br
      rounded-tr
      border-l-0;
  }

  .LibraryHomeSection_scrollNavigation___right {
    @apply -right-14
      rounded-bl
      rounded-tl
      border-r-0;
  }
}
