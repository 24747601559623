.Error {
  @apply fixed
    flex
    flex-col
    h-full
    inset-4
    justify-center
}

.Error_logo {
  @apply absolute top-4 left-4
}

/* #region ErrorUI component styles */

.ErrorUI {
  @apply flex
    flex-col
    items-center
    w-full
}

.ErrorUI_icon {
  @apply text-5xl
}

.ErrorUI_title {
  @apply my-4 text-5xl
}

.ErrorUI_content {
  @apply max-w-lg text-center
}
/* #endregion */